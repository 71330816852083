import { RecurentPaymentComponent } from './recurentpayment/recurentpayment.component';
import { MessageDialogComponent } from 'app/modules/tms/shipping/add-edit/message-dialog/message-dialog.component';
import { SelectDaterangeDialogComponent } from './../modules/tms/shipping/select-daterange-dialog/select-daterange-dialog.component';
import { RerStatusTransformPipe } from './../modules/rer/_pipe/rerStatusTransform.pipe';
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { StringfilterPipe } from '../pipe/stringfilter.pipe';
import { ShrinkfilterPipe } from '../pipe/shrinkfilter.pipe';
import { ElipsizeTextPipe } from '../pipe/elipsizeText.pipe';
import { SanitizeUrlPipe } from '../pipe/sanitizeurl.pipe';
import { UnitMeasurementPipe } from '../pipe/unitmeasurement.pipe';
import { LeftPadFilter } from '../pipe/leftpadfilter.pipe';
import { DateOffsetipe } from '../pipe/dateoffset.pipe';
import { VehicleStateDuration } from '../pipe/vehiclestateduration.pipe';
import { TimeDuration } from '../pipe/timeduration.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { FloatPipe } from '../pipe/float.pipe';

import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatepickerRangeComponent } from './datepicker-range/datepicker-range.component';
import { AddRouteDialogComponent } from './add-route-dialog/add-route-dialog.component';
import { CustomTimeIntervalComponent } from './custom-time-interval/custom-time-interval.component';
import { NotificationComponent } from './notification/notification.component';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';
import { RefuelingChartComponent } from './refueling-chart/refueling-chart.component';
import { AddEditGeofenceComponent } from './add-edit-geofence/add-edit-geofence.component';
import { CoordonateInfoComponent } from './coordonate-info/coordonate-info.component';
import { PhoneNumberInputComponent } from './phone-number-input/phone-number-input.component';
import { PhoneNumberOnlyDirective } from './phone-number-input/phone-number-input.directive';
import { BtpaymentComponent } from './btpayment/btpayment.component';
import { InvoicelistComponent } from './invoicelist/invoicelist.component';
import { InvoicelistfilterComponent } from './invoicelistfilter/invoicelistfilter.component';
import { EuplatescpaymentComponent } from './euplatescpayment/euplatescpayment.component';
import { ReviewListComponent } from '../modules/pgs/review-list/review-list.component'
import { ScrollSpyDirective } from './directives/scrollspy.directive';
import { ReviewDialogComponent } from 'app/modules/pgs/dialogs/review-dialog/review-dialog.component';
import { CargoboxTransactionDetailComponent } from './cargobox-transaction-detail/cargobox-transaction-detail.component';
import { NoSpacesDirective } from './directives/no-space.directive';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgScrollbarModule,
        MatInputModule,
        MatFormFieldModule,
        NgSelectModule,
        MatButtonModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatMenuModule,
        MatIconModule,
        MatSelectModule,
        MatDialogModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatListModule,
        MatExpansionModule,
        MatNativeDateModule,
        MatCardModule,
        MatRadioModule,
        MatDividerModule,
        MatTreeModule,
        MatToolbarModule,
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
    ],
    declarations: [
        StringfilterPipe,
        ShrinkfilterPipe,
        ElipsizeTextPipe,
        SanitizeUrlPipe,
        LeftPadFilter,
        VehicleStateDuration,
        TimeDuration,
        DateOffsetipe,
        UnitMeasurementPipe,
        ClickOutsideDirective,
        DatepickerComponent,
        DatepickerRangeComponent,
        AddRouteDialogComponent,
        CustomTimeIntervalComponent,
        NotificationComponent,
        ConfirmActionComponent,
        RefuelingChartComponent,
        AddEditGeofenceComponent,
        CoordonateInfoComponent,
        PhoneNumberInputComponent,
        PhoneNumberOnlyDirective,
        BtpaymentComponent,
        InvoicelistComponent,
        InvoicelistfilterComponent,
        EuplatescpaymentComponent,
        ReviewListComponent,
        RerStatusTransformPipe,
        SelectDaterangeDialogComponent,
        MessageDialogComponent,
        ScrollSpyDirective,
        ReviewDialogComponent,
        FloatPipe,
        RecurentPaymentComponent,
        CargoboxTransactionDetailComponent,
        NoSpacesDirective
    ],
    exports: [
        StringfilterPipe,
        ShrinkfilterPipe,
        ElipsizeTextPipe,
        SanitizeUrlPipe,
        VehicleStateDuration,
        TimeDuration,
        DateOffsetipe,
        UnitMeasurementPipe,
        LeftPadFilter,
        ClickOutsideDirective,
        DatepickerComponent,
        DatepickerRangeComponent,
        CustomTimeIntervalComponent,
        RefuelingChartComponent,
        AddEditGeofenceComponent,
        CoordonateInfoComponent,
        PhoneNumberInputComponent,
        InvoicelistComponent,
        InvoicelistfilterComponent,
        NgScrollbarModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatMenuModule,
        MatIconModule,
        MatSelectModule,
        MatDialogModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatListModule,
        MatExpansionModule,
        MatNativeDateModule,
        MatCardModule,
        MatRadioModule,
        MatDividerModule,
        MatTreeModule,
        MatToolbarModule,
        ReviewListComponent,
        RerStatusTransformPipe,
        SelectDaterangeDialogComponent,
        MessageDialogComponent,
        ScrollSpyDirective,
        TranslateModule,
        FontAwesomeModule,
        FloatPipe,
        CargoboxTransactionDetailComponent,
        NoSpacesDirective
    ]
})
export class SharedModule { }
